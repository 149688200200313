import axios from "axios";
import ProviderDTO from "@/models/ProviderDTO";

export const providers = {
    all: async (): Promise<ProviderDTO[]> => {
        let resp = await axios({
            url: `/providers/all`,
            method: 'GET'
        });

        return resp.data as ProviderDTO[]
    },
    updatePriorities: async (priorities: any): Promise<void> => {
        let resp = await axios({
            url: `/providers/priorities`,
            method: 'PUT',
            data: {
                ...priorities
            }
        });
    },
}